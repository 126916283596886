import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import TwitterButton from "./TwitterButton";
import FacebookButton from "./FacebookButton";

function SocialSharing({ message, score, questions }) {
  return (
    <View style={styles.socials}>
      <View style={styles.twitter}>
        <TwitterButton message={message} score={score} questions={questions} />
      </View>
      <View style={styles.facebook}>
        <FacebookButton message={message} score={score} questions={questions} />
      </View>
    </View>
  );
}

export default SocialSharing;

const styles = StyleSheet.create({
  socials: {
    flexDirection: "row",
  },
  twitter: {
    marginRight: 20,
  },
  facebook: {
    marginLeft: 20,
  },
});
