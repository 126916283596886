import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
} from "react-native";

function Answers({ correctAnswer, incorrectAnswers, handleQuestion }) {
  const [answered, setAnswered] = useState(false);
  const [data, setData] = useState([]);

  function onSelect(correct) {
    setAnswered(true);
    setTimeout(function () {
      handleQuestion(correct);
      setAnswered(false);
    }, 1000);
  }

  useEffect(() => {
    const correct = {
      id: "1",
      answer: correctAnswer,
      correct: true,
    };
    const data = [
      {
        id: "2",
        answer: incorrectAnswers[0],
        correct: false,
      },
      {
        id: "3",
        answer: incorrectAnswers[1],
        correct: false,
      },
      {
        id: "4",
        answer: incorrectAnswers[2],
        correct: false,
      },
    ];
    data.sort(function () {
      return 0.5 - Math.random();
    });
    const correctIndex = Math.floor(Math.random() * 4);
    data.splice(correctIndex, 0, correct);
    setData(data);
  }, [correctAnswer, incorrectAnswers]);

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => onSelect(item.correct)}
        style={item.correct && answered ? styles.buttonCorrect : styles.button}
      >
        <Text style={styles.text}>{item.answer}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        extraData={answered}
      />
    </View>
  );
}

export default Answers;

const styles = StyleSheet.create({
  button: {
    padding: 10,
    borderRadius: 4,
    margin: 10,
    borderColor: "#D9CCBF",
    borderWidth: 1,
  },
  buttonCorrect: {
    padding: 10,
    borderRadius: 4,
    margin: 10,
    borderColor: "#D9CCBF",
    borderWidth: 1,
    backgroundColor: "rgba(191,96,103, 0.8)",
  },
  text: {
    color: "#D9CCBF",
    fontSize: 14,
  },
});
