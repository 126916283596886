import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

function MainButton({ handlePress, buttonText }) {
  return (
    <View style={styles.wrap}>
      <TouchableOpacity style={styles.button} onPress={() => handlePress()}>
        <Text style={styles.text}>{buttonText}</Text>
      </TouchableOpacity>
    </View>
  );
}

export default MainButton;

const styles = StyleSheet.create({
  wrap: {
    alignItems: "center'",
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "transparent",
    borderColor: "#D9CCBF",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 4,
    margin: 10,
    fontSize: 16,
    color: "#D9CCBF",
    width: 225,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#D9CCBF",
    fontSize: 30,
  },
});
