import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

function MainButton({ handlePress, page }) {
  return (
    <View>
      <TouchableOpacity style={styles.button} onPress={() => handlePress(page)}>
        <Text style={styles.text}>{page}</Text>
      </TouchableOpacity>
    </View>
  );
}

export default MainButton;

const styles = StyleSheet.create({
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "transparent",
    borderColor: "#D9CCBF",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 4,
    margin: 10,
    fontSize: 16,
    color: "#D9CCBF",
    width: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#D9CCBF",
    fontSize: 30,
  },
});
