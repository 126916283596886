import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import { byPercentType } from "../graphql/queries";
import ResultsButton from "./ResultsButton";
import Logo from "./Logo";
import SocialSharing from "./SocialSharing";

function getResponseGroup(percent) {
  if (percent === 0) {
    return "0";
  }
  if (percent <= 0.2) {
    return "1-2";
  }
  if (percent <= 0.4) {
    return "3-4";
  }
  if (percent <= 0.6) {
    return "5-6";
  }
  if (percent <= 0.85) {
    return "7-8";
  }
  if (percent <= 0.99) {
    return "9";
  }
  return "10";
}

async function getResponses(percentGroup) {
  const responses = await API.graphql({
    query: byPercentType,
    variables: {
      percent: percentGroup,
    },
  });
  return responses.data.byPercentType.items;
}

function ResultText({ text }) {
  return (
    <View>
      <Text>{text}</Text>
    </View>
  );
}

function ShareText({ text }) {
  return (
    <View>
      <Text>{text}</Text>
    </View>
  );
}

function Results({ score, questions, startNewGame, updatePage }) {
  ////console.log("quesitons", questions);
  const [loaded, setLoaded] = useState(false);
  const [responseResult, setResponseResult] = useState("");
  const [responseShare, setResponseShare] = useState("");
  const responseGroup = getResponseGroup(score / questions);
  const responses = getResponses(responseGroup);

  useEffect(() => {
    async function updateResponses() {
      const responses = await getResponses(responseGroup);
      ////console.log(responses);
      const results = responses.filter((e) => e.type === "result");
      const shares = responses.filter((e) => e.type === "share");
      ////console.log("results", results);
      ////console.log("shares", shares);
      const randomResult = results[Math.floor(Math.random() * results.length)];
      const randomShare = shares[Math.floor(Math.random() * shares.length)];
      ////console.log("randomResult", randomResult.text);
      ////console.log("randomShare", randomShare.text);
      setResponseResult(randomResult.text);
      setResponseShare(randomShare.text);
      setLoaded(true);
    }
    updateResponses();
  }, []);

  return (
    <View>
      {loaded && (
        <View style={styles.wrap}>
          <Text style={styles.scoreText}>
            {score} / {questions}
          </Text>
          <Text style={styles.resultText}>{responseResult}</Text>
          {loaded && (
            <SocialSharing
              message={responseShare}
              score={score}
              questions={questions}
            />
          )}
          <ResultsButton handlePress={startNewGame} buttonText={"New Game"} />
          <ResultsButton
            handlePress={() => updatePage("main")}
            buttonText={"Home"}
          />
        </View>
      )}
    </View>
  );
}

export default Results;

const styles = StyleSheet.create({
  wrap: {
    alignItems: "center",
    justifyContent: "center",
  },
  resultText: {
    color: "#D9CCBF",
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  scoreText: {
    color: "#D9CCBF",
    fontSize: 32,
    textAlign: "center",
    marginBotton: 10,
  },
});

export { getResponseGroup };
