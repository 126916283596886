import React, { useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

function Logo({ handlePress, page }) {
  return (
    <View>
      <TouchableOpacity style={styles.button} onPress={() => handlePress(page)}>
        <Image
          source={require("../assets/logo-dark.png")}
          style={styles.image}
        />
      </TouchableOpacity>
    </View>
  );
}

export default Logo;

const styles = StyleSheet.create({
  image: {
    width: 240,
    height: 150,
  },
});
