import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

function MainButton({ updateDifficulty, selected, difficulty }) {
  return (
    <View>
      <TouchableOpacity
        style={selected ? styles.buttonSelected : styles.button}
        onPress={() => updateDifficulty(difficulty)}
      >
        <Text style={styles.text}>{difficulty}</Text>
      </TouchableOpacity>
    </View>
  );
}

export default MainButton;

const styles = StyleSheet.create({
  button: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: "transparent",
    borderColor: "#BF6067",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 4,
    margin: 10,
    width: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSelected: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: "rgba(191,96,103, 0.5)",
    borderColor: "#BF6067",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 4,
    margin: 10,
    width: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#D9CCBF",
    fontSize: 14,
  },
});
