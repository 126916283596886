import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import Logo from "./Logo";
import MainButton from "./MainButton";
import DifficultyButton from "./DifficultyButton";

function Custom({ updatePage, gameDetails, setGameDetails }) {
  const [difficulty, setDifficulty] = useState("medium");
  const increaseQuestions = () => {
    if (gameDetails.questions >= 25) {
      return;
    }
    const questionsNum = gameDetails.questions + 1;
    setGameDetails({
      difficulty: difficulty,
      questions: questionsNum,
    });
  };
  const decreaseQuestions = () => {
    if (gameDetails.questions <= 10) {
      return;
    }
    const questionsNum = gameDetails.questions - 1;
    setGameDetails({
      gametype: difficulty,
      questions: questionsNum,
    });
  };
  const updateDifficulty = (diff) => {
    setDifficulty(diff);
    setGameDetails({
      difficulty: diff,
      questions: gameDetails.questions,
    });
  };
  return (
    <View style={styles.pageWrapper}>
      <View styles={styles.logoWrap}>
        <Logo handlePress={updatePage} page={"main"} />
      </View>
      <View style={styles.numberControl}>
        <TouchableOpacity onPress={decreaseQuestions}>
          <Text style={styles.controls}>-</Text>
        </TouchableOpacity>
        <Text style={styles.gameNumber}>{gameDetails.questions}</Text>
        <TouchableOpacity onPress={increaseQuestions}>
          <Text style={styles.controls}>+</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.difficultyButtons}>
        <DifficultyButton
          updateDifficulty={updateDifficulty}
          selected={difficulty === "easy"}
          difficulty={"easy"}
        />
        <DifficultyButton
          updateDifficulty={updateDifficulty}
          selected={difficulty === "medium"}
          difficulty={"medium"}
        />
        <DifficultyButton
          updateDifficulty={updateDifficulty}
          selected={difficulty === "hard"}
          difficulty={"hard"}
        />
      </View>

      <MainButton handlePress={updatePage} page={"game"} />
    </View>
  );
}

export default Custom;

const styles = StyleSheet.create({
  logoWrap: {
    marginBottom: 20,
  },
  pageWrapper: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  numberControl: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "cener",
  },
  controls: {
    fontSize: 40,
    color: "#D9CCBF",
  },
  gameNumber: {
    fontSize: 40,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 20,
    marginRight: 20,
    color: "#D9CCBF",
  },
  difficultyButtons: {
    flexDirection: "row",
  },
});
