/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:683f79af-302c-4225-acd1-6654c0b006ff",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_mQPlGytVz",
    "aws_user_pools_web_client_id": "2hbkn2mgm487osvluo54odnale",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mzmnsuqerbfa5dm6fsn7in76ce.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;
