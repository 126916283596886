import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { API, graphqlOperation } from "aws-amplify";
import { listQuestions, byCategory } from "../graphql/queries";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Logo from "./Logo";
import Question from "./Question";
import Answers from "./Answers";
import Results from "./Results";
import SpinnerWeb from "./SpinnerWeb";
import QuizReset from "./QuizReset";

function makeCategoriesObj(number) {
  // makes an object of catagories for the game
  // takes 1 from each caterogy and randomly selects the rest.
  const categories = [
    "geography",
    "entertainment",
    "history",
    "arts-literature",
    "science-nature",
    "sports-leisure",
  ];
  const questionTypes = categories.map((e) => {
    return { category: e };
  });
  const extraQuestions = number - 6;
  for (let i = 0; i < extraQuestions; i++) {
    let newCategory = categories[Math.floor(Math.random() * categories.length)];
    questionTypes.push({ category: newCategory });
  }
  return questionTypes;
}

function makeDifficultiesObj(number, difficulty) {
  ////console.log("difficulty", difficulty);
  let difficulties = ["easy", "medium", "hard"];
  let geniusChance = 0.5;
  if (difficulty === "easy") {
    difficulties = ["easy", "medium"];
    geniusChance = 0;
  }
  if (difficulty === "hard") {
    difficulties = ["medium", "hard"];
    geniusChance = 1;
  }
  const difficultyNum = Math.floor(number / 3.1);
  const SelectedDifficulties = `,${difficulties.join(",")}`
    .repeat(difficultyNum)
    .split(",")
    .slice(1);
  if (Math.random() < geniusChance) {
    SelectedDifficulties.push("genius");
  }
  const difficultiesLeft = number - SelectedDifficulties.length;
  for (let i = 0; i < difficultiesLeft; i++) {
    let newDifficulty =
      difficulties[Math.floor(Math.random() * difficulties.length)];
    SelectedDifficulties.push(newDifficulty);
  }
  return SelectedDifficulties;
}

function makeQuestionList(questionTypes, selectedDifficulties) {
  const questionList = questionTypes.map((e, i) => {
    e["difficulty"] = selectedDifficulties[i];
    return e;
  });

  return questionList.reduce((acc, curr) => {
    if (Object.keys(acc).includes(curr.category)) {
      acc[curr.category].push(curr.difficulty);
    } else {
      acc[curr.category] = [curr.difficulty];
    }
    return acc;
  }, {});
}

function makeGameQuestions(number, difficulty) {
  const questionTypes = makeCategoriesObj(number);
  shuffle(questionTypes);

  const selectedDifficulties = makeDifficultiesObj(number, difficulty);
  shuffle(selectedDifficulties);

  const questionList = makeQuestionList(questionTypes, selectedDifficulties);
  return questionList;
}

function getQuestionbyCategory(category) {
  ////console.log("running getQuestionbyCategory");
  // Add try execpt block to return a reply the network is not reachable
  const questions = API.graphql({
    query: byCategory,
    variables: {
      category: category,
    },
  });
  ////console.log("recieved the data in function");
  return questions;
}

function difficultyTotals(difficultiesList) {
  const easy = countElements(difficultiesList, "easy");
  const medium = countElements(difficultiesList, "medium");
  const hard = countElements(difficultiesList, "hard");
  const genius = countElements(difficultiesList, "genius");
  return {
    easyQuestions: easy,
    mediumQuestions: medium,
    hardQuestions: hard,
    geniusQuestions: genius,
  };
}

function getQuestionsbyDifficulty(questionsList, difficulty, num) {
  ////console.log("number of questions", num);
  const filteredQuestions = questionsList.filter(
    (item) => item.difficulty === difficulty
  );
  shuffle(filteredQuestions);
  return filteredQuestions.slice(0, num);
}

async function getExtraQuestions(num, ids) {
  //console.log("getting extra questions: ", num);
  const categories = [
    "geography",
    "entertainment",
    "history",
    "arts-literature",
    "science-nature",
    "sports-leisure",
  ];
  let questions = [];
  // const ids = gameQuestions.map((item) => item.id);
  for (let category of categories) {
    const catQuestionsData = await getQuestionbyCategory(category);
    const catQuestions = catQuestionsData.data.byCategory.items;
    questions = [...questions, ...catQuestions];
  }
  const questionsNotUsed = [];
  for (let question of questions) {
    if (!ids.includes(question.id)) {
      questionsNotUsed.push(question);
    }
  }
  shuffle(questionsNotUsed);
  return questionsNotUsed.slice(0, num);
}

async function getTakenQuestionsNumber() {
  const questionNumberTaken =
    (await AsyncStorage.getItem("questionsTakenNumber")) || 0;
  return Number(questionNumberTaken);
}

async function setTakenQuestionsNumber(num) {
  await AsyncStorage.setItem("questionsTakenNumber", num);
}

async function getTakenQuestions() {
  const jsonValue = await AsyncStorage.getItem("questionsTakenList");
  return jsonValue != null ? JSON.parse(jsonValue) : [];
}

async function setTakenQuestions(ids) {
  const jsonValue = JSON.stringify(ids);
  await AsyncStorage.setItem("questionsTakenList", jsonValue);
}

function Game({ updatePage, gameDetails }) {
  //console.log("gameDetails", gameDetails);
  const [isLoaded, setIsLoaded] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [questionReset, setQuestionReset] = useState(false);
  const [newGame, setNewGame] = useState(true);

  async function fetchQuestions(num, difficulty) {
    //console.log("number of questions", num);
    // Get the number of questions taken and questions IDs from local storage to exlude them from selections
    let questionsTakenNumber = await getTakenQuestionsNumber();
    let questionsTaken = await getTakenQuestions();
    //console.log("questionsTakenNumber", questionsTakenNumber);
    // Updating the questionsTaken localStorage and checking if its reacched 300 yet.  If it has reached 300 the store is cleared and started again
    if (questionsTakenNumber > 325) {
      setTakenQuestionsNumber(num);
      setTakenQuestions([]);
      questionsTakenNumber = num;
      questionsTaken = [];
      setQuestionReset(true);
    } else {
      setTakenQuestionsNumber(num + questionsTakenNumber);
    }

    const questionsRequest = makeGameQuestions(num, difficulty);
    let gameQuestions = [];
    for (let category of Object.keys(questionsRequest)) {
      let {
        easyQuestions,
        mediumQuestions,
        hardQuestions,
        geniusQuestions,
      } = difficultyTotals(questionsRequest[category]);
      // Need to get all of the questions for each category
      // We will remove any question that has already been used before
      const questionsListData = await getQuestionbyCategory(category);
      const questionsList = [];
      for (let question of questionsListData.data.byCategory.items) {
        if (!questionsTaken.includes(question.id)) {
          questionsList.push(question);
        }
      }
      //console.log(category);
      //console.log("questionsList", questionsList);
      if (easyQuestions > 0) {
        const newEasyQuestion = getQuestionsbyDifficulty(
          questionsList,
          "easy",
          easyQuestions
        );
        gameQuestions = [...gameQuestions, ...newEasyQuestion];
      }
      if (mediumQuestions > 0) {
        const newMediumQuestion = getQuestionsbyDifficulty(
          questionsList,
          "medium",
          mediumQuestions
        );
        gameQuestions = [...gameQuestions, ...newMediumQuestion];
      }
      if (hardQuestions > 0) {
        const newHardQuestion = getQuestionsbyDifficulty(
          questionsList,
          "hard",
          hardQuestions
        );
        gameQuestions = [...gameQuestions, ...newHardQuestion];
      }
      if (geniusQuestions > 0) {
        const newGeniusQuestion = getQuestionsbyDifficulty(
          questionsList,
          "genius",
          geniusQuestions
        );
        gameQuestions = [...gameQuestions, ...newGeniusQuestion];
      }
    }
    ////console.log("gameQuestions", gameQuestions);
    if (gameQuestions.length < num) {
      ////console.log("NEED MORE QUESTIONS!!");
      // if the question count still isn't enough add extra medium questions
      const ids = gameQuestions.map((item) => item.id);
      const allIds = [...ids, ...questionsTaken];
      const missingQuestions = num - gameQuestions.length;
      const extraQuestions = await getExtraQuestions(missingQuestions, allIds);
      gameQuestions = [...gameQuestions, ...extraQuestions];
    }
    const questionIds = gameQuestions.map((item) => item.id);
    // //console.log("questionsTaken", questionsTaken);
    setTakenQuestions([...questionsTaken, ...questionIds]);
    shuffle(gameQuestions);
    setQuestions(gameQuestions);
    setIsLoaded(true);
  }

  const handleQuestion = (correct) => {
    if (correct) {
      setScore(score + 1);
    }
    if (activeQuestion === gameDetails.questions - 1) {
      setIsFinished(true);
    }
    setActiveQuestion(activeQuestion + 1);
  };

  const startNewGame = () => {
    // ////console.log("starting new game");
    setIsLoaded(false);
    setScore(0);
    setActiveQuestion(0);
    setIsFinished(false);
    fetchQuestions(gameDetails.questions, gameDetails.difficulty);
    // setNewGame(true);
  };

  useEffect(() => {
    ////console.log("running useeffect");
    fetchQuestions(gameDetails.questions, gameDetails.difficulty);
  }, []);
  // ////console.log(activeQuestion);
  // changes for spinner ####################
  return (
    <View>
      {isFinished ? (
        <Results
          score={score}
          questions={gameDetails.questions}
          startNewGame={startNewGame}
          updatePage={updatePage}
        />
      ) : isLoaded ? (
        questionReset ? (
          <QuizReset handlePress={() => setQuestionReset(false)} />
        ) : (
          <View>
            <Question question={questions[activeQuestion].question} />
            <Answers
              handleQuestion={handleQuestion}
              correctAnswer={questions[activeQuestion].correctAnswer}
              incorrectAnswers={[
                questions[activeQuestion].incorrectAnswer1,
                questions[activeQuestion].incorrectAnswer2,
                questions[activeQuestion].incorrectAnswer3,
              ]}
            />
          </View>
        )
      ) : (
        <SpinnerWeb />
      )}
    </View>
  );
}

export default Game;

function shuffle(data) {
  // shuffle the questions using Fisher-Yates Algorithm
  for (let i = data.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = data[i];
    data[i] = data[j];
    data[j] = temp;
  }
  return data;
}

function countElements(list, string) {
  return list.reduce((acc, curr) => {
    if (curr === string) {
      return acc + 1;
    }
    return acc;
  }, 0);
}

export {
  makeCategoriesObj,
  makeDifficultiesObj,
  makeQuestionList,
  getQuestionbyCategory,
};
