/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      expirationDate
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $id: ID
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byQuestion = /* GraphQL */ `
  query ByQuestion(
    $question: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byQuestion(
      question: $question
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByExpirationDate = /* GraphQL */ `
  query QuestionsByExpirationDate(
    $expirationDate: AWSDateTime
    $difficultyCategory: ModelQuestionQuestionsByExpirationDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByExpirationDate(
      expirationDate: $expirationDate
      difficultyCategory: $difficultyCategory
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCategory = /* GraphQL */ `
  query ByCategory(
    $category: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCategory(
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        expirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewQuestion = /* GraphQL */ `
  query GetNewQuestion($id: ID!) {
    getNewQuestion(id: $id) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      status
      source
      createdAt
      updatedAt
    }
  }
`;
export const listNewQuestions = /* GraphQL */ `
  query ListNewQuestions(
    $id: ID
    $filter: ModelNewQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNewQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        status
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byNewQuestion = /* GraphQL */ `
  query ByNewQuestion(
    $question: String
    $sortDirection: ModelSortDirection
    $filter: ModelNewQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNewQuestion(
      question: $question
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        status
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byNewStatus = /* GraphQL */ `
  query ByNewStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelNewQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNewStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        correctAnswer
        incorrectAnswer1
        incorrectAnswer2
        incorrectAnswer3
        difficulty
        category
        status
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      percent
      type
      text
      createdAt
      updatedAt
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $id: ID
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        percent
        type
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPercentType = /* GraphQL */ `
  query ByPercentType(
    $percent: String
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPercentType(
      percent: $percent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        percent
        type
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
