import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ImageBackground,
} from "react-native";
import Amplify from "aws-amplify";
import config from "./aws-exports";
Amplify.configure(config);
import MainPage from "./components/MainPage";
import Custom from "./components/Custom";
import Game from "./components/Game";
import EndScore from "./components/EndScore";

function App() {
  const [activePage, setActivePage] = useState("main");
  const [gameDetails, setGameDetails] = useState({
    difficulty: "medium",
    questions: 10,
  });

  const updatePage = (page) => {
    setActivePage(page);
  };
  // ////console.log(activePage);
  ////console.log(gameDetails);
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("./assets/main-back-02.png")}
        style={styles.image}
      >
        {activePage === "main" && <MainPage updatePage={updatePage} />}
        {activePage === "custom" && (
          <Custom
            updatePage={updatePage}
            gameDetails={gameDetails}
            setGameDetails={setGameDetails}
          />
        )}
        {activePage === "game" && (
          <Game updatePage={updatePage} gameDetails={gameDetails} />
        )}
        {activePage === "end" && <EndScore updatePage={EndScore} />}
      </ImageBackground>
    </View>
  );
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#15202A",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});
