import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

function QuizReset({ handlePress }) {
  return (
    <View style={styles.wrap}>
      <Text style={styles.textSmaller}>
        Well done, you have done all the questions. We'll reset so you can keep
        playing, but you will see some repeats now.
      </Text>
      <TouchableOpacity onPress={handlePress} style={styles.button}>
        <Text style={styles.text}>Continue</Text>
      </TouchableOpacity>
    </View>
  );
}

export default QuizReset;

const styles = StyleSheet.create({
  wrap: {
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "transparent",
    borderColor: "#D9CCBF",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 4,
    margin: 10,
    fontSize: 16,
    color: "#D9CCBF",
    width: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#D9CCBF",
    fontSize: 30,
  },
  textSmaller: {
    color: "#D9CCBF",
    fontSize: 22,
  },
});
