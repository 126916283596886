import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Linking,
  Platform,
  Image,
} from "react-native";

function TwitterButton({ message, score, questions }) {
  const shareMessage = `${score} out of ${questions}...  ${message} \r\n\r\n@thegeneralquiz\r\n\r\n`;
  const twitterIntent = "https://twitter.com/intent/tweet";
  const url = "https://www.thegeneralquiz.com";
  const shareURL = new URL(`${twitterIntent}?url=${url}&text=${shareMessage}`);
  const handlePress = () => {
    if (Platform.OS == "web") {
      window.open(shareURL, "_blank");
    } else {
      Linking.openURL(shareURL, window.open);
    }
  };
  return (
    <View>
      <TouchableOpacity onPress={handlePress}>
        <Image source={require("../assets/twitter.png")} style={styles.image} />
      </TouchableOpacity>
    </View>
  );
}

export default TwitterButton;

const styles = StyleSheet.create({
  image: {
    width: 40,
    height: 40,
    opacity: 0.7,
  },
});
