import React, { useState } from "react";
import { StyleSheet, Text, View, ImageBackground } from "react-native";
import Logo from "./Logo";
import MainButton from "./MainButton";

function Main({ updatePage }) {
  return (
    <View style={styles.wrapper}>
      <View style={styles.logoWrap}>
        <Logo handlePress={updatePage} page={"main"} />
      </View>
      <MainButton handlePress={updatePage} page={"game"} />
      <MainButton handlePress={updatePage} page={"custom"} />
    </View>
  );
}

export default Main;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});
