import React, { useState } from "react";
import { StyleSheet, Text, View, TextInput } from "react-native";

function EndScore() {
  return (
    <View>
      <Text>This is the end</Text>
    </View>
  );
}

export default EndScore;
