import React from "react";
import { StyleSheet, Text, View } from "react-native";

function Question({ question }) {
  ////console.log("loading question");
  return (
    <View style={styles.wrapper}>
      <Text testID="question" style={styles.text}>
        {question}
      </Text>
    </View>
  );
}

export default Question;

const styles = StyleSheet.create({
  text: {
    color: "rgba(217,204,191,1)",
    fontSize: 20,
  },
  wrapper: {
    paddingLeft: 15,
    paddingRight: 15,
  },
});
